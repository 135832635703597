import React, { ChangeEvent, createRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FurnitureModel } from 'api/model/furniture';
import { isValidFurnitureType } from '../../../libs/filetype';
import * as Sentry from '@sentry/browser';
import Circular from '../../atoms/Circular';
import ModalBase from '../../atoms/ModalBase';
import FurnitureSlide from '../FurnitureSlide';

export type FormData = {
  furnitureId?: number;
  name: string;
  width: number;
  depth: number;
  height: number;
  place: string;
  purchase: boolean;
  memo: string;
  files: FileData[];
};

const defaultProps = {
  loading: false,
};

export type ModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (params: FormData, files: object[]) => void;
  furniture?: FurnitureModel | null;
  loading: boolean;
  viewMode: boolean;
  setViewMode: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNoEditAlert: React.Dispatch<React.SetStateAction<boolean>>;
  designApprovalCompletion: boolean;
  handedOver4monthsAgo: boolean;
};

export type FileData = {
  id?: number;
  previewUrl: string;
  file?: File;
};

export default function FurnitureModal(props: ModalProps) {
  const {
    onClose,
    open,
    onSubmit,
    furniture,
    loading,
    viewMode,
    setViewMode,
    setOpenNoEditAlert,
    designApprovalCompletion,
    handedOver4monthsAgo,
  } = props;
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [files, setFiles] = useState<FileData[]>([]);
  const [openSlide, setOpenSlide] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    if (furniture && furniture.images.length > 0) {
      setFiles(
        furniture.images.map((image) => ({
          id: image.id,
          previewUrl: image.url,
        }))
      );
    }
  }, [furniture]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    formState,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      furnitureId: furniture?.id,
      name: furniture?.name,
      width: furniture?.width,
      depth: furniture?.depth,
      height: furniture?.height,
      place: furniture?.place,
      purchase: furniture?.purchase,
      memo: furniture?.memo,
    },
  });

  const handleClose = () => {
    setFiles([]);
    onClose();
    clearErrors('files');
  };

  const _onSubmit = handleSubmit(async (params) => {
    if (designApprovalCompletion || handedOver4monthsAgo) {
      setOpenNoEditAlert(true);
    } else if (viewMode) {
      setViewMode(false);
    } else {
      try {
        params.width = removeDecimalPoint(params.width);
        params.height = removeDecimalPoint(params.height);
        params.depth = removeDecimalPoint(params.depth);
        await onSubmit(params, files);
        setFiles([]);
        onClose();
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
  });

  const handleFileOpen = () => {
    if (_ref.current) {
      _ref.current.click();
    }
  };

  const handleFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) {
      return;
    }
    const file = event.target.files.item(0);
    if (file === null) {
      return;
    }
    if (file.size >= 20000000) {
      setError('files', {
        type: 'error',
        message: '20MB以下の画像をアップロードしてください',
      });
      setFileErrorMessage('20MB以下の画像をアップロードしてください');
      return;
    }
    if (!isValidFurnitureType(file.type)) {
      setError('files', {
        type: 'error',
        message: 'jpeg, jpg, png, gif以外は投稿できません',
      });
      setFileErrorMessage('jpeg, jpg, png, gif以外は投稿できません');
      return;
    }
    clearErrors('files');
    setFiles((prevState) => [
      ...prevState,
      { previewUrl: URL.createObjectURL(file), file: file },
    ]);
  };

  const _ref = createRef<HTMLInputElement>();

  const sizeFormValidation = (value: number) => {
    if (isNaN(Number(value))) {
      return '半角数字で入力してください';
    }
    const numbers = String(value).split('.');
    if (numbers[1] && numbers[1].length >= 2) {
      return '小数第一位まで入力可能です';
    }
  };

  // 100. => 100 のように小数点で止まった値は小数点を削除して返す。
  const removeDecimalPoint = (value: number) => {
    if (!value) return value;
    const numbers = String(value).split('.');
    if (numbers[1] === '') {
      return Number(numbers[0]);
    }
    return value;
  };

  // 新規作成・編集モード・参照モードを表示する関数
  const getTitleText = () => {
    if (viewMode) {
      return '参照モード';
    } else if (furniture) {
      return '編集モード';
    } else {
      return '新規作成';
    }
  };

  const isDisabled = () => {
    return !formState.isValid;
  };

  const handleSlideClose = () => {
    setOpenSlide(false);
  };

  return (
    <>
      {openSlide && furniture && (
        <FurnitureSlide
          furniture={furniture}
          onClose={handleSlideClose}
          imageIndex={imageIndex}
        />
      )}
      <ModalBase onClose={handleClose} open={open} className="Modal">
        <form className="Modal" onSubmit={_onSubmit}>
          <div className="Modal__content Modal__content-furniture">
            <div className="Modal__nav">
              <p className="Modal__nav-left" onClick={() => handleClose()}>
                キャンセル
              </p>
              <p className="Modal__title">{getTitleText()}</p>

              {loading ? (
                <Circular className="Modal__progress" />
              ) : (
                <button className="Modal__nav-right" disabled={isDisabled()}>
                  {viewMode ? '編集' : '保存'}
                </button>
              )}
            </div>
            <div className="Modal__main">
              <div className="Modal__inner">
                <input
                  {...register('furnitureId', {})}
                  name="furnitureId"
                  type="hidden"
                />
                <div className="Modal__image-box">
                  {files.map((file, index) => (
                    <div className="Modal__image-add -media" key={index}>
                      {!viewMode && (
                        <span
                          onClick={() => {
                            setFiles(
                              files.filter(
                                (f) => f.previewUrl !== file.previewUrl
                              )
                            );
                            if (_ref.current) _ref.current.value = '';
                          }}
                          className="delete-btn"
                        />
                      )}

                      <span
                        className="image-wrap"
                        onClick={() => {
                          if (furniture) {
                            setImageIndex(index);
                            setOpenSlide(true);
                          }
                        }}
                      >
                        <img src={file.previewUrl} alt={file.file?.name} />
                      </span>
                    </div>
                  ))}
                  {!viewMode && files.length !== 3 && (
                    <div className="Modal__image-add" onClick={handleFileOpen}>
                      <span className="image-wrap">
                        <img
                          src="/assets/img/messages/btn/add_image.svg"
                          alt="ユーザイメージ"
                        />
                        <input
                          hidden
                          id="fileUpload"
                          type="file"
                          ref={_ref}
                          onChange={(e) => handleFiles(e)}
                        />
                      </span>
                    </div>
                  )}
                </div>
                <div className="Modal__error">
                  {errors.files && fileErrorMessage}
                </div>
                <input
                  {...register('name', { required: true })}
                  disabled={furniture?.is_default || viewMode}
                  className="Modal__input-text"
                  type="text"
                  name="name"
                  autoComplete="off"
                  maxLength={40}
                  placeholder="名称（必須 40文字以内）"
                />
                <div className="Modal__error">
                  {errors.name?.type === 'required' && (
                    <p>名称を入力してください</p>
                  )}
                </div>
                <div className="Modal__input-size">
                  <p>サイズ</p>
                  <div className="Modal__input-size-inner">
                    <dl>
                      <dt>幅 (W)</dt>
                      <dd>
                        <input
                          {...register('width', {
                            validate: (value) => sizeFormValidation(value),
                          })}
                          type="text"
                          name="width"
                          maxLength={6}
                          disabled={viewMode}
                        />
                        <span>cm</span>
                      </dd>
                      <dt>奥行き (D)</dt>
                      <dd>
                        <input
                          {...register('depth', {
                            validate: (value) => sizeFormValidation(value),
                          })}
                          type="text"
                          name="depth"
                          maxLength={6}
                          disabled={viewMode}
                        />
                        <span>cm</span>
                      </dd>
                      <dt>高さ (H)</dt>
                      <dd>
                        <input
                          {...register('height', {
                            validate: (value) => sizeFormValidation(value),
                          })}
                          type="text"
                          name="height"
                          maxLength={6}
                          disabled={viewMode}
                        />
                        <span>cm</span>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div className="Modal__error">
                  {errors.width?.type === 'validate' && (
                    <p>{errors.width?.message}</p>
                  )}
                  {errors.depth?.type === 'validate' && (
                    <p>{errors.depth?.message}</p>
                  )}
                  {errors.height?.type === 'validate' && (
                    <p>{errors.height?.message}</p>
                  )}
                </div>
                <input
                  {...register('place', {})}
                  className="Modal__input-text"
                  type="text"
                  maxLength={25}
                  placeholder="ご新居で使用される場所（25文字以内）"
                  name="place"
                  disabled={viewMode}
                />
                <div className="Modal__switchToggle">
                  <p>購入予定</p>
                  <input
                    {...register('purchase', {})}
                    className="Btn__switchtgl"
                    id="input01"
                    type="checkbox"
                    name="purchase"
                    disabled={viewMode}
                  />
                  <label className="Btn__switchtgl-btn" htmlFor="input01" />
                </div>
                <textarea
                  {...register('memo', {})}
                  className="Modal__textarea"
                  name="memo"
                  rows={4}
                  cols={20}
                  placeholder="備考（メーカー名、品番、車種　等 190文字以内）"
                  maxLength={190}
                  disabled={viewMode}
                />
              </div>
            </div>
          </div>
        </form>
      </ModalBase>
    </>
  );
}

FurnitureModal.defaultProps = defaultProps;
